<template>
  <router-view />
</template>
<script>
import Navbar from "./components/Navbar";
import carrusel from "./components/carrusel.vue";

export default {
  compoment: {
    Navbar,
    carrusel,
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c4964;
}

nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
  color: #2c4964;
}

nav a.router-link-exact-active {
  color: #ff7a0e;
}
</style>
