<template>
  <div class="carouselcontainer">
    <button>
      <a class="arrow prev" @click="movePrev">&#10094;</a>
    </button>
    <div class="carousel1">
      <div
        v-for="(photo, index) in photos"
        :key="index"
        :style="{ transform: `translateX(-${currentIndex * 0}%)` }"
        class="slide1"
      >
        <img :src="photo" />
      </div>
    </div>
    <button>
      <a class="arrow next" @click="moveNext">&#10095;</a>
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      photos: [
        require("../assets/logos/HEMODIALISIS.png"),
        require("../assets/logos/PERITONEAL.png"),
        require("../assets/logos/PSICOLOGIA.png"),
        require("../assets/logos/NUTRICION.png"),
        require("../assets/logos/NEFROLOGIA.png"),
        require("../assets/logos/NEFOLOGIA-PEDIATRICA.png"),
        require("../assets/logos/UROLOGIA.png"),
        require("../assets/logos/CARDIOLOGIA.png"),
        require("../assets/logos/ENDOCRINOLOGIA.png"),
        require("../assets/logos/MEDICINA-INTERNA.png"),
        require("../assets/logos/MEDICINA-FAMILIAR.png"),

        // Agrega más imágenes según sea necesario
      ],
      currentIndex: 0,
    };
  },
  mounted() {
    this.startCarousel();
  },
  methods: {
    startCarousel() {
      this.animationFrame = requestAnimationFrame(this.moveNext);
    },
    moveNext(timestamp) {
      if (!this.lastTimestamp) {
        this.lastTimestamp = timestamp;
      }

      const elapsed = timestamp - this.lastTimestamp;

      if (elapsed > 2500) {
        // Ajusta este valor para cambiar la velocidad
        this.currentIndex = (this.currentIndex + 1) % this.photos.length;
        this.photos.push(this.photos.shift());

        this.lastTimestamp = timestamp;
      }

      this.animationFrame = requestAnimationFrame(this.moveNext);
    },
    movePrev(timestamp) {
      if (!this.lastTimestamp) {
        this.lastTimestamp = timestamp;
      }

      const elapsed = timestamp - this.lastTimestamp;

      if (elapsed > 2500) {
        // Ajusta este valor para cambiar la velocidad
        this.currentIndex =
          (this.currentIndex - 1 + this.photos.length) % this.photos.length;
        this.photos.unshift(this.photos.pop());

        this.lastTimestamp = timestamp;
      }

      this.animationFrame = requestAnimationFrame(this.movePrev);
    },
  },
  beforeDestroy() {
    cancelAnimationFrame(this.animationFrame);
  },
};
</script>
<style>
.prev,
.next {
  cursor: pointer;
  width: auto;
  padding: 6px;
  color: rgb(243, 134, 0);
  font-weight: bold;
  font-size: 28px;
  transition: 0.7s ease;
  border-radius: 100px;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(243, 134, 0);
  background-hover: rgba(255, 255, 255);
}
.carouselcontainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  scroll-behavior: smooth;
}

.carousel1 {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.slide1 {
  min-width: 30%;
}

.slide1 img {
  width: 95%;
  height: 100%;
  object-fit: cover;
}
</style>
