<template>
  <div class="text-center text-black">
    <div>
      <form id="form">
        <div class="grid max-w-3x1 gap-1 px-1 sm:grid-cols-2 bg-white">
          <div class="grid">
            Nombre
            <div
              class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
            >
              <input
                type="text"
                v-model="nombre"
                name="name"
                id="nombre"
                class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
                placeholder="Ejemplo: Andrés Felipe Medina"
              />
              <!-- <label
              html="first-name"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
            ></label> -->
            </div>
          </div>
          <div class="grid">
            Correo
            <div
              class="bg-white first:flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
            >
              <input
                type="text"
                name="email"
                id="last-name"
                class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
                placeholder="Ejemplo:andres@gmail.com"
              />
              <!-- <label
              html="last-name"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
            ></label> -->
            </div>
          </div>
          <div class="grid">
            Celular
            <div
              class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
            >
              <input
                type="text"
                name="phone"
                id="last-name"
                class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
                placeholder="Ejemplo:839 317 5834"
              />
              <!-- <label
              html="company"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
              >Company</label
            > -->
            </div>
          </div>
          <div class="grid">
            Dirección
            <div
              class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
            >
              <input
                type="address"
                name="address"
                id="address"
                class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
                placeholder="Calle20 #13-64"
              />
              <!-- <label
              html="email"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
              >E-mail</label
            > -->
            </div>
          </div>
          <div class="grid">
            Mensaje
            <div
              class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
            >
              <textarea
                type="text"
                name=""
                id="text"
                class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-00 focus:ring-0"
                placeholder="Describa el motivo de su cita"
                required=""
                style="height: 36px"
              ></textarea>

              <!-- <label
              html="email"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
              >E</label
            > -->
            </div>
          </div>

          <br />
        </div>
        <div class="flex items-center max-w-3xl bg-white p-1">
          <input
            type="checkbox"
            name="check"
            id="check"
            required
            style="
              transform: scale(0.7);
              width: 25px;
              height: 25px;
              margin-right: 0.5rem;
              margin-left: 5rem;
            "
          />
          <label for="check" style="white-space: nowrap"
            >Acepto Términos, condiciones y políticas de privacidad</label
          >
        </div>

        <div class="text-center">
          <button
            type="submit"
            @click="enviarFormulario"
            class="mt-2 bg-orange-500 text-white py-2 px-4 rounded-md w-20 hover:bg-orange-600 mx-auto"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script></script>
