<template>
  <link
    rel="stylesheet"
    href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"
  />
  <!-- <header class="w-full top-0 z-50 fixed "> -->
  <div class="fixed w-full z-50">
    <nav
      class="md:flex bg-white shadow dark:bg-gray-800 w-full z-50 h-20 link-router-active"
    >
      <div class="container">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="flex items-center justify-between">
            <div class="logo-container">
              <!-- <a href=""> -->
              <img
                href="https://nefrouros.net/"
                class="w-40 md:ml-32 ml-5"
                src="../assets/logos/05.png"
                @click="$router.push('/')"
              />
              <!-- </a> -->

              <!-- Mobile menu button -->
              <div class="md:flex lg:hidden">
                <button
                  @click="desplegar()"
                  type="button"
                  class="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
                  aria-label="toggle menu"
                >
                  <svg
                    viewBox="0 0 24 24"
                    class="w-6 h-6 mr-5 mt-5 fill-current"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
          <div
            class="lg:flex lg:mt-5"
            v-bind="{ block: showMenu, hidden: !showMenu }"
          >
            <ul
              class="bg-white md:px-2 ml-auto md:flex md:space-x-2 absolute md:relative top-full left-0 right-0"
            >
              <li>
                <a
                  href="https://nefrouros.net/"
                  class="lg:p-4 py-3 px-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro"
                >
                  Inicio
                </a>
              </li>

              <!-- dropdown -->

              <li class="relative parent">
                <div class="flex">
                  <div
                    @mouseover="mostrar"
                    @mouseout="mostrar"
                    type="button"
                    class="bg-white md:flex m-auto space-x-2 lg:p-4 px-0 border-b-2 border-transparent hover:border-otherblue hover:text-graynefro md:w-full font-sans font-semibold"
                    aria-label="toggle menu"
                  >
                    Organización
                    <svg
                      class="h-5 w-5 flex text-gray-800 mt-1 transform"
                      :class="showSubMenu ? 'rotate-180' : ''"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <div>
                      <div
                        v-bind="{ block: showSubMenu, hidden: !showSubMenu }"
                        class="md:absolute bg-white md:w-48 md:mt-10 md:-ml-40 md:shadow-xl align-items-center"
                      >
                        <a
                          href="https://nefrouros.net/Servicios"
                          class="py-2 block font-sans font-semibold border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Servicios
                        </a>

                        <a
                          href="https://nefrouros.net/Plataforma-Estrategica"
                          class="py-1 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Plataformas Estratégicas
                        </a>

                        <a
                          href="https://nefrouros.net/Codigos-De-Etica"
                          class="py-2 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Código De Ética</a
                        >
                        <a
                          href="https://nefrouros.net/Estados-Financieros"
                          class="py-1 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Estados Financieros</a
                        >
                        <a
                          href="https://nefrouros.net/Sarlaft"
                          class="py-1 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >SARLAFT</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <!-- fin dropdown -->
              <!--Dropdown siau-->

              <li class="relative parent">
                <div class="flex">
                  <div
                    @mouseover="ver"
                    @mouseout="ver"
                    type="button"
                    class="bg-white m-auto md:inline-flex space-x-2 lg:p-4 px-0 border-b-2 border-transparent hover:border-otherblue hover:text-graynefro md:w-full font-sans font-semibold"
                    aria-label="toggle menu"
                  >
                    Pacientes
                    <svg
                      class="h-5 w-5 text-gray-800 mt-1 transform"
                      :class="showSubMenul ? 'rotate-180' : ''"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <div>
                      <div
                        v-bind="{ block: showSubMenul, hidden: !showSubMenul }"
                        class="md:absolute bg-white md:w-48 md:mt-10 md:-ml-32 md:shadow-xl"
                      >
                        <a
                          href="https://nefrouros.net/Calendario-De-Actividades"
                          class="py-4 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Calendario Actividades
                        </a>
                        <a
                          href="https://nefrouros.net/Derechos-Y-Deberes"
                          class="py-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Derechos y Deberes
                        </a>

                        <a
                          href="https://nefrouros.net/Valore-Agregados"
                          class="py-3 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Valores Agregados</a
                        >
                        <a
                          href="https://nefrouros.net/Actividades-Pacientes"
                          class="py-1 px-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Actividades Pacientes</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <!-- sedes -->
              <li class="relative parent">
                <div class="flex">
                  <div
                    @mouseover="mirar"
                    @mouseout="mirar"
                    type="button"
                    class="bg-white m-auto md:inline-flex space-x-2 lg:p-4 px-0 border-b-2 border-transparent hover:border-otherblue hover:text-graynefro md:w-full font-sans font-semibold"
                    aria-label="toggle menu"
                  >
                    Sedes
                    <svg
                      class="h-5 w-5 text-gray-800 mt-1 transform"
                      :class="showSubMenu2 ? 'rotate-180' : ''"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <div>
                      <div
                        v-bind="{ block: showSubMenu2, hidden: !showSubMenu2 }"
                        class="md:absolute bg-white md:w-48 md:mt-10 md:-ml-32 md:shadow-xl"
                      >
                        <a
                          href="https://nefrouros.net/Sede-27-Colombia"
                          class="py-4 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Colombia
                        </a>
                        <a
                          href="https://republicadominicana.nefrouros.net/"
                          class="py-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro text-sm"
                          >Rep. Dominicana
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!--Fin dropdown-->
              <li>
                <a
                  href="https://nefrouros.net/Blog-de-Noticias"
                  class="lg:p-4 py-3 px-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro"
                >
                  <span>Noticias</span>
                </a>
              </li>
              <li>
                <a
                  href="https://nefrouros.net/Preguntas-frecuentes"
                  class="lg:p-4 py-3 px-0 block font-sans font-semibold text-red border-b-2 border-transparent hover:border-otherblue hover:text-graynefro"
                >
                  <span>Preguntas Frecuentes</span>
                </a>
              </li>
              <div
                class="d-flex justify-content-end"
                style="padding-right: 50px; display: flex"
              >
                <a
                  href="https://nefrouros.net/Sede-27-Colombia"
                  style="margin-right: 10px"
                >
                  <img src="../assets/logos/colombia.png" />
                </a>
                <a href="https://republicadominicana.nefrouros.net/">
                  <img src="../assets/logos/dominicana.png" />
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <!-- </header> -->
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  props: {
    array: Array,
  },
  setup() {
    let showSubMenu = ref(false);
    let showMenu = ref(false);
    let showEstadosFinacieros = ref(false);
    function desplegar() {
      showMenu.value = !showMenu.value;
    }
    function mostrar() {
      showSubMenu.value = !showSubMenu.value;
    }
    let showSubMenul = ref(false);
    function ver() {
      showSubMenul.value = !showSubMenul.value;
    }
    let showSubMenu2 = ref(false);
    function mirar() {
      showSubMenu2.value = !showSubMenu2.value;
    }
    let showSubMenuEF = () => {
      showEstadosFinacieros.value = !showEstadosFinacieros.value;
    };

    return {
      desplegar,
      showMenu,
      mostrar,
      showSubMenu,
      showSubMenul,
      showSubMenu2,
      ver,
      mirar,
      showSubMenuEF,
      showEstadosFinacieros,
    };
  },
};
</script>

<style></style>
