<template>
  <section>
    <div class="bg-contain grid md:grid-cols-2 z-20 relative -mt-50">
      <div class="bg-otherblue p-8 border-black">
        <img
          src="../assets/logos/PORTADA1.jpg"
          alt="Campana Institucional"
          class="w-40px mx-auto mb-4"
        />
        <button
          class="border-2 mt-5 rounded-md py-1 px-5 text-left text-orange-500 hover:bg-orange-500 hover:text-white"
        >
          <a
            href="https://nefrouros.net/actividades-Santo%20Domingo%20%28RD%29-25"
            ><b
              >CAMPAÑA INSTITUCIONAL: LA DIALISIS ES VIDA, DISFRUTA TU VIDA</b
            ></a
          >
        </button>
      </div>
      <div class="bg-otherblue p-8 border-black">
        <img
          src="../assets/logos/PORTADA2.jpg"
          alt="Campana Institucional"
          class="w-40px mx-auto mb-4"
        />
        <button
          class="border-2 mt-5 rounded-md py-1 px-5 text-left text-orange-500 hover:bg-orange-500 hover:text-white"
        >
          <a href="https://nefrouros.net/actividades-Republica%20musica-26"
            ><b>SOMOS MÁS QUE HACER DIÁLISIS</b></a
          >
        </button>
      </div>
      <!-- <div class="bg-otherblue p-8 border-black">
        <img
          src="../assets/logos/PORTADA3.jpg"
          alt="Campana Institucional"
          class="w-40px mx-auto mb-4"
        />
        <button
          class="border-2 mt-5 rounded-md py-1 px-5 text-left text-orange-500 hover:bg-orange-500 hover:text-white"
        >
          <a
            href="https://nefrouros.net/actividades-Santo%20Domingo%20%28RD%29-25"
            >SEMANA DE MÚSICA</a
          >
        </button>
      </div> -->
    </div>
  </section>
</template>

<style>
.image-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.album-image {
  max-width: 300px;
  max-height: 200px;
  margin: 0 10px;
}
</style>
