<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <Navbar />
  <br />
  <br />
  <br />
  <br />

  <!-- start template of video  -->
  <div class="flex flex-col">
    <div class="flex flex-col justify-center">
      <div class="relative">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/JVbYpZjk_4s?rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
  <div class="mt-5"></div>
  <!-- end template of video  -->
  <!-- start orange bar whith message -->
  <div
    class="bg-orange-500 h-[45px] text-white font-semibold flex justify-center items-center text-xl"
  >
    NEFROUROS EL MEJOR LUGAR DONDE UN PACIENTE CON ENFERMEDAD RENAL PUEDE ESTAR
  </div>
  <br />
  <!-- start orange bar whith message -->
  <!-- start colums with ubication and form -->

  <div class="grid grid-cols-3">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <div class="mb-4 md:mb-0">
      <iframe
        style=""
        src="https://embed.waze.com/iframe?zoom=17&lat=18.484524&lon=-69.916574&ct=livemap"
        class="w-full h-40 md:h-64"
        allowfullscreen
      ></iframe>
    </div>
    <div class="container">
      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="info text-lg">
            <div class="address">
              <i class="icofont-google-map"></i>
              <h4 class="text-orange-500 font-semibold">UBICACIÓN</h4>
              <p>
                Avenida San Martín 202 <br />
                Ensanche la Fe
              </p>
              <p>Santo Domingo R.D.</p>
            </div>
            <br />
            <br />

            <div class="phone">
              <i class="icofont-telephone" href="https://wa.me/8093845911"></i>
              <h4 class="text-orange-500 font-semibold text-lg">CONTÁCTENOS</h4>
              <p>Teléfono: 809 384 5911</p>
              <p>Celular: 849 473 3458</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div><Form /></div>
  </div>
  <!-- end colums with ubication and form -->
  <br />
  <div
    class="h-[100px] font-semibold flex justify-center items-center text-5xl"
  >
    <h2 class="text-gray-500 mx-5">NUESTROS</h2>
    <h2 class="text-orange-500">SERVICIOS</h2>
  </div>
  <div><carrusel /></div>
  <br />
  <br />
  <div
    class="h-[100px] font-semibold flex justify-center items-center text-4xl"
  >
    <h2 class="text-gray-500 mx-5">NUESTROS</h2>
    <h2 class="text-orange-500">CONVENIOS</h2>
  </div>
  <div><convenios /></div>

  <img
    src="../assets/logos/BANDERA-01.jpg"
    alt="100"
    style="width: 100%; max-height: 50px"
  />
  <br /><br />
  <div
    class="h-[100px] font-semibold flex justify-center items-center text-5xl"
  >
    <h2 class="text-gray-500 mx-5">¿QUÉ HACEMOS</h2>
    <h2 class="text-orange-500">POR LA VIDA?</h2>
  </div>

  <album />

  <br /><br />
  <redes />
  <footer class="footer">
    <div class="footer-top">
      <div class="container">
        <div
          class="h-[80px] flex flex-col"
          style="
            text-align: left;
            margin-left: 10%;
            font-size: 18px;
            color: black;
          "
        >
          <h2>© Copyright IT Management Zomac S.A.S. All Rights Reserved</h2>
          <h2>Desarrollado por IT Management</h2>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/Navbar";
import Form from "../components/Form";
import carrusel from "../components/carrusel";
import convenios from "../components/convenios";
import album from "../components/album.vue";
import redes from "../components/redes.vue";
export default {
  name: "HomeView",
  components: {
    Navbar,
    Form,
    carrusel,
    convenios,
    album,
    redes,
  },
};
</script>
<style>
/* Estilos para el footer */
.footer {
  color: #444444;
  font-size: 14px;
  background: #f1f7fd;
}
.footer-top {
  padding: 40px 0 30px 0;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
}
</style>
